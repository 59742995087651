.sell-cellphone-page {
  min-height: 47vh;

  .sell-cellphone-form {
    display: inline-flex;
    flex-flow: row wrap;
    width: 100%;
  }

  .card-selection {
    width: 100%;
  }

  .card-selection:before,
  .card-selection:after {
    content: " ";
    display: table;
  }

  .card-selection:after {
    clear: both;
  }

  .card-selection-4,
  .card-selection-3 {
      .card-selection-item {
      position: relative;
      float: left;
      width: 50%;
      min-height: 1px;
      padding-left: 15px;
      padding-right: 15px;
      position: relative;
      min-height: 1px;
      padding-left: 15px;
      padding-right: 15px;

      &:focus {
        border: 2px solid #472d8c;
      }

      .card--active {
        border: 2px solid #16a085;
      }

      button {
        width: 100%;
      }

      @media (min-width: 992px) {
        float: left;
        width: 25%;
      }
    }
  }

  .card-selection-3 {
    .card-selection-item {
      position: relative;
      min-height: 1px;
      padding-left: 15px;
      padding-right: 15px;

      @media (min-width: 992px) {
        float: left;
        width: 33.33333%;
      }

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  .card-selection-title {
    font-weight: bold
  }

  @media (max-width: 992px) {
    .aside-main {
      .aside,
      .main {
        width: 100%
      }
    }
  }
}