.to-sell-page {
  .jumbotron {
    position: relative;
    background-color: #eee;
    padding: 2em 1em;
    background-color: #472d8c;
    margin-bottom: 2em;

    .btn-theme {
      padding: 1em 4em;
    }
  }

  .jumbotron-title {
    color: #fff;
    font-family: "Fjalla One", sans-serif;
    margin: 0;
    font-size: 30px;
  }

  .jumbotron-p {
    color: #fff;
    font-size: 18px;
    margin-top: 0;
  }

  .jumbotron-title--center {
    text-align: center;
  }

  .jumbotron-banner-title {
    font-size: 24px;
    margin: 0;
  }

  .jumbotron-banner-p {
    color: #fff;
    margin-top: 0;
  }

  .jumbotron-banner {
    text-align: center;
    padding: 0.5em 0;
  }

  .jumbotron-list {
    display: table;
    margin: 0 auto;
    color: #fff;
  }

  .jumbotron-list {
    padding: 2em 0;
  }

  .jumbotron-list .list-inline li span[class^="icon-"] {
    font-size: 24px;
  }

  @media (min-width: 768px) {
    .jumbotron {
      padding: 3em 1em;
    }
    .jumbotron-container {
      display: table;
      width: 100%;
    }
    .jumbotron-column {
      display: table-cell;
      vertical-align: middle;
      padding: 1em;
    }
    .jumbotron-title {
      font-size: 56px;
    }
    .jumbotron-banner {
      text-align: left;
    }
    .jumbotron-banner--right {
      text-align: right;
    }
    .jumbotron-p {
      font-size: 24px;
    }
    .jumbotron-list {
      padding: 0;
    }
  }
}

.comments-rate {
	font-size: 5em;
	font-weight: bold
}

.comments-stars {
	display: table;
  margin: 0 auto;

  & span {
    float: left;
    color: #fff;
    font-size: 1.5em;
  }
}

.comments-total {
	color: #fff;
}

.comments-logo-footer {
	width: 100px !important;
	margin: 0 auto;
}

.comments-logo {
	padding: 1em;
}

.comments-logo--ml {
	background: #fddd00;
	border-bottom-right-radius: 8px;
	border-bottom-left-radius: 8px;
}

.resume--comments {
	padding: 0!important;
}
