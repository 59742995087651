.footer {
	margin-top: 2em;
	padding: 4em 0;
	background-color: #15181d;
	color: #eee;

  p {
    font-size: 14px;
  }

  a {
    color: #eee;
  }

  h4 {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 1em;
  }

  ul {
    li {
      margin-bottom: 0.5em;
    }
  }

  .box-footer {
    margin-bottom: 2em;
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .box-footer-row {
    border-top: 1px solid #555;
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .box-footer-row:before,
  .box-footer-row:after {
    content: " ";
    display: table;
  }

  .box-footer-row:after {
    clear: both;
  }

  .footer-details p,
  .footer-details a {
    display: inline-block;
    color: #999;
    font-size: 12px;
  }

  .footer-details a {
    float: right;
    margin-left: 15px;
    margin-top: 15px;
  }

  @media (min-width: 768px) {
    .box-footer {
      float: left;
      width: 33.33333%;
    }

    .box-footer-row {
      float: left;
      width: 100%;
    }
  }
}