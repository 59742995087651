.header {
  background-color: #472d8c;
  color: #fff;

  &:before {
    content: " ";
    display: table;
  }

  &:after {
    content: " ";
    display: table;
    clear: both;
  }

  .container {
    padding-left: 0;
    padding-right: 0
  }

  .header-section {
    display: block;
  }

  .header-brand {
    display: table;
    width: 100%;
  }

  .header-brand-logo {
    padding-top: 1em;
    padding-left: 1em;
    padding-bottom: 5px;
  }

  .header-brand-left,
  .header-brand-right {
    display: table-cell;
    vertical-align: middle;
  }

  .header-brand-logo {
    display: block;
  }

  .header-navigation-mobile {
    float: right;
  }

  .header-navigation-mobile:before,
  .header-navigation-mobile:after {
    content: " ";
    display: table;
  }

  .header-navigation-mobile:after {
    clear: both;
  }

  .header-navigation-mobile-sections:before,
  .header-navigation-mobile-sections:after {
    content: " ";
    display: table;
  }

  .header-navigation-mobile-sections:after {
    clear: both;
  }

  .header-navigation-mobile-sections li {
    display: inline-block;
    float: left;
  }

  .header-navigation-mobile-sections li a {
    display: block;
    padding: 1em;
    color: #fff;
    font-weight: bold;
  }

  .header-navigation-mobile-sections li a span[class^="icon-"] {
    font-size: 16px;
  }

  .header-navigation--rs {
    display: none;
  }

  .header-navigation-sections li {
    display: block;
    border-bottom: 1px solid #3d2779;
  }

  .header-navigation-sections li a {
    display: block;
    padding: 2em 0.75em;
    color: #fff;
    font-weight: bold;
  }

  .header-navigation-sections li a span[class^="icon-"] {
    font-size: 18px;
  }

  .header-navigation-sections li a:hover {
    color: #fddd00;
  }

  .header-navigation-active a {
    color: #fddd00 !important;
  }

  .header-menu-mobile {
    display: none;
  }

  .heder-show {
    display: block !important;
  }

  @media (min-width: 768px) {
    display: block;

    &:before,
    &:after {
      content: " ";
      display: table;
    }

    &:after {
      clear: both;
    }

    .container {
      padding-left: 15px;
      padding-right: 15px;
    }

    .header-menu-mobile {
      display: block;
    }

    .header-section {
      display: inline-block;
      float: left;
    }

    .header-section-right {
      float: right;
    }

    .header-brand-right {
      display: none;
    }

    .header-brand-logo {
      padding-top: 1.25em;
      padding-left: 0;
      padding-right: 1em;
      padding-bottom: 0;
    }

    .header-navigation-sections:before,
    .header-navigation-sections:after {
      content: " ";
      display: table;
    }

    .header-navigation-sections:after {
      clear: both;
    }

    .header-navigation-sections li {
      display: inline-block;
      float: left;
      border-bottom: none;
    }

    .header-data-icon-text--mobile {
      display: none;
    }
  }
}