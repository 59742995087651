.how-it-works-page {
  .card--step {
    min-height: 230px;
  }

  @media (max-width: 768px) {
    .card--step {
      min-height: 300px;
    }
  }

  @media (max-width: 320px) {
    .card--step {
      min-height: 250px;
    }
  }
}
