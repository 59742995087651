@import url("https://fonts.googleapis.com/css?family=Fjalla+One|Open+Sans");
@import './scss/normalize';

html,
body {
	-webkit-font-smoothing: antialiased !important;
	font-family: "Open Sans", sans-serif;
	background-color: #fff;
}

ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

h3,
h4 {
	font-weight: normal;
}

p {
	font-size: 16px
}

a {
	cursor: pointer;
	color: #472d8c;
	-webkit-transition: 0.3s Ease-in-out;
	-o-transition: 0.3s Ease-in-out;
  transition: 0.3s Ease-in-out;

  &:hover,
  &:focus {
    color: #3d2779;
    -webkit-transition: 0.3s Ease-in-out;
    -o-transition: 0.3s Ease-in-out;
    transition: 0.3s Ease-in-out;
    text-decoration: none;
  }
}

@font-face {
	font-family: "hf-fonts";
	src: url("./fonts/hf-fonts.eot");
	src: url("./fonts/hf-fonts.eot?#iefix") format("embedded-opentype"), url("./fonts/hf-fonts.woff") format("woff"), url("./fonts/hf-fonts.ttf") format("truetype"), url("./fonts/hf-fonts.svg#hf-fonts") format("svg");
	font-weight: normal;
	font-style: normal;
}

.container {
	margin-right: auto;
	margin-left: auto;
	padding-left: 15px;
  padding-right: 15px;

  &:before {
    content: " ";
    display: table;
  }

  &:after {
    content: " ";
    display: table;
    clear: both;
  }

  @media (min-width: 768px) {
    width: 750px;
  }

  @media (min-width: 992px) {
    width: 970px;
  }

  @media (min-width: 1200px) {
    width: 1170px;
  }
}

.container-sm {
	margin-right: auto;
	margin-left: auto;
	padding-left: 15px;
	padding-right: 15px;
	margin-top: 15px;
}

.container-sm:before,
.container-sm:after {
	content: " ";
	display: table;
}

.container-sm:after {
	clear: both;
}

@media (min-width: 768px) {
	.container-sm {
		width: 750px;
	}
}

.container-fluid {
	margin-right: auto;
	margin-left: auto;
	padding-left: 15px;
	padding-right: 15px;
}

.container-fluid:before,
.container-fluid:after {
	content: " ";
	display: table;
}

.container-fluid:after {
	clear: both;
}

.banner-full-row {
	background: #fff;
}

.aside-main:before,
.aside-main:after,
.grid-items:before,
.grid-items:after {
	content: " ";
	display: table;
}

.aside-main:after,
.grid-items:after {
	clear: both;
}

.aside-main {
	margin-top: 15px;
}

.aside-main .aside {
	position: relative;
	min-height: 1px;
	padding-left: 15px;
	padding-right: 15px;
}

@media (min-width: 768px) {
	.aside-main .aside {
		float: left;
		width: 25%;
	}
}

.aside-main .main {
	position: relative;
	min-height: 1px;
	padding-left: 15px;
	padding-right: 15px;
}

@media (min-width: 768px) {
	.aside-main .main {
		float: left;
		width: 75%;
	}
}

@media (max-width: 768px) {
	.aside-main .aside,
	.aside-main .main {
		padding-left: 0px;
		padding-right: 0px;
	}
}

.grid-items-inline {
	margin-top: 15px;
	margin-bottom: 15px;
}

.grid-items {
	margin-left: -15px;
	margin-right: -15px;
}

.grid-items-2-col li {
	position: relative;
	min-height: 1px;
	padding-left: 15px;
	padding-right: 15px;
}

@media (min-width: 768px) {
	.grid-items-2-col li {
		float: left;
		width: 50%;
	}
}

.grid-items-3-col li {
	position: relative;
	min-height: 1px;
	padding-left: 15px;
	padding-right: 15px;
}

@media (min-width: 768px) {
	.grid-items-3-col li {
		float: left;
		width: 33.33333%;
	}
}

@media (max-width: 992px) {
	.grid-items-3-col li {
		position: relative;
		min-height: 1px;
		padding-left: 15px;
		padding-right: 15px;
	}
}

@media (max-width: 992px) and (min-width: 768px) {
	.grid-items-3-col li {
		float: left;
		width: 50%;
	}
}

.grid-items-4-col li {
	position: relative;
	min-height: 1px;
	padding-left: 15px;
	padding-right: 15px;
}

@media (min-width: 768px) {
	.grid-items-4-col li {
		float: left;
		width: 25%;
	}
}

@media (max-width: 992px) {
	.grid-items-4-col li {
		position: relative;
		min-height: 1px;
		padding-left: 15px;
		padding-right: 15px;
	}
}

@media (max-width: 992px) and (min-width: 768px) {
	.grid-items-4-col li {
		float: left;
		width: 50%;
	}
}

.grid:before,
.grid:after {
	content: " ";
	display: table;
}

.grid:after {
	clear: both;
}

.grid-3-col .grid-item {
	position: relative;
	min-height: 1px;
	padding-left: 15px;
	padding-right: 15px;
}

@media (min-width: 768px) {
	.grid-3-col .grid-item {
		float: left;
		width: 33.33333%;
	}
}

@media (max-width: 992px) {
	.grid-3-col .grid-item {
		position: relative;
		min-height: 1px;
		padding-left: 15px;
		padding-right: 15px;
	}
}

@media (max-width: 992px) and (min-width: 768px) {
	.grid-3-col .grid-item {
		float: left;
		width: 50%;
	}
}

[data-icon]:before {
	font-family: "hf-fonts" !important;
	content: attr(data-icon);
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	vertical-align: middle;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
	font-family: "hf-fonts" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	vertical-align: middle;
}

.icon-android-arrow-back:before {
	content: "\61";
}

.icon-android-arrow-down:before {
	content: "\62";
}

.icon-android-arrow-forward:before {
	content: "\63";
}

.icon-android-arrow-up:before {
	content: "\64";
}

.icon-android-call:before {
	content: "\65";
}

.icon-android-close:before {
	content: "\66";
}

.icon-android-done:before {
	content: "\67";
}

.icon-android-favorite:before {
	content: "\68";
}

.icon-android-mail:before {
	content: "\69";
}

.icon-android-map:before {
	content: "\6a";
}

.icon-android-pin:before {
	content: "\6b";
}

.icon-android-star:before {
	content: "\6c";
}

.icon-android-star-half:before {
	content: "\6d";
}

.icon-android-star-outline:before {
	content: "\6e";
}

.icon-arrow-down-b:before {
	content: "\6f";
}

.icon-arrow-up-b:before {
	content: "\70";
}

.icon-checkmark:before {
	content: "\71";
}

.icon-close:before {
	content: "\72";
}

.icon-social-apple:before {
	content: "\73";
}

.icon-social-android:before {
	content: "\74";
}

.icon-social-instagram:before {
	content: "\75";
}

.icon-social-twitter:before {
	content: "\76";
}

.icon-social-whatsapp:before {
	content: "\77";
}

.icon-social-youtube:before {
	content: "\78";
}

.icon-social-facebook:before {
	content: "\79";
}

.icon-cash:before {
	content: "\7a";
}

.icon-ribbon-b:before {
	content: "\41";
}

.icon-unlocked:before {
	content: "\42";
}

.icon-thumbsup:before {
	content: "\43";
}

.icon-shield:before {
	content: "\44";
}

.icon-android-phone-portrait:before {
	content: "\45";
}

.icon-android-contact:before {
	content: "\46";
}

.icon-ios-location:before {
	content: "\47";
}

.icon-flag:before {
	content: "\48";
}

.btn-theme {
	display: inline-block;
	padding: 1em;
	line-height: 24px;
	font-weight: bold;
	position: relative;
	color: #472d8c;
	text-align: center;
	background: #fddd00;
	border-radius: 5px;
	font-size: 16px;
	border: none;
}

.btn-theme:hover,
.btn-theme:focus {
	cursor: pointer;
	text-decoration: none;
	background: #f3d400;
}

.btn-theme-link {
	border-bottom: 1px dashed #fddd00;
}

.text-promo {
	color: #16a085;
}

.text-red {
	color: #c0392b !important;
}

.text-green {
	color: #27ae60 !important;
}

.text-orange {
	color: #f39c12 !important;
}

.text-left {
  text-align: left;
}

small {
	color: #777;
}

.enfatize-text {
	color: #fddd00 !important;
}

.img-responsive {
	display: block;
	max-width: 100%;
	height: auto;
  width: 100%;

  &.default-icon {
    margin-top: 28px;
  }
}

.img-center {
	margin: 0 auto;
	display: table;
}

.list-inline:before,
.list-inline:after {
	content: " ";
	display: table;
}

.list-inline:after {
	clear: both;
}

.list-inline li {
	display: inline-block;
	float: left;
	padding-left: 0.5em;
	padding-right: 0.5em;
}

.list-inline li p {
	font-size: 14px;
	margin: 0.5em 0 0em 0;
	font-weight: bold;
}

.list-inline--center {
	text-align: center;
}

.list-inline--item span[class^="icon-"],
.list-inline--item p {
	float: left;
	color: #fff;
}

.list-inline--iconsBrand {
	margin-bottom: 1em;
}

.list-inline--iconsBrand li span[class^="icon-"] {
	font-size: 24px;
	margin-right: 5px;
}

.list-bullets {
	list-style: disc;
	margin-left: 1.5em;
}

.list-bullets li {
	margin-bottom: 0.5em;
}

.list-bullets li p {
	margin: 0;
}

.text-logo {
	font-family: "Fjalla One", sans-serif;
}

.enfatize-text {
  color: #472d8c !important;
}

.line-title {
	display: block;
	width: 100%;
	position: relative;
	margin: 0 0 15px 0;
}

.line-title-hr {
	display: block;
	width: 100%;
	height: 2px;
	background-color: #eee;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}

.line-title-text {
	display: inline-block;
	background-color: #fff;
	position: relative;
	z-index: 10;
	padding: 10px 10px 10px 0;
	font-weight: bold;
	font-size: 24px;
}

.line-title--center {
	text-align: center;
}

.line-title--center .line-title-text {
	padding: 10px;
}

.line-title-action .line-title-text {
	font-size: 24px !important;
}

.line-title-action {
  & a {
    background-color: #fcfcfc;
    position: relative;
    z-index: 10;
    padding: 15px 0px 10px 10px;
    font-weight: bold;
    font-size: 14px;
    float: right;

    @media (max-width: 767px) {
      display: none;
    }
  }
}

.card {
	border: 2px solid #eee;
	padding: 10px 12px;
	background-color: #fff;
	margin-bottom: 15px;
	position: relative;
	-webkit-transition: 0.3s Ease-in-out;
	-o-transition: 0.3s Ease-in-out;
	transition: 0.3s Ease-in-out;
	border-top-right-radius: 8px;
	border-top-left-radius: 8px;
	border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;

  &:before,
  &:after {
    content: " ";
    display: table;
  }

  &:after {
    content: " ";
    clear: both;
  }
}

.card--step {
  @media (min-width: 768px) {
    min-height: 300px;
  }
}

.card-header,
.card-body {
	padding: 0.5em 0;
}

.card-body {
  &:before,
  &:after {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }
}

.card-header-title {
	margin: 0;
	font-weight: bold;
}

.card-body-column {
	float: left;
	padding-left: 0.5em;
	padding-right: 0.5em;
}

.resume {
	color: #fff;
	background: #472d8c;
	text-align: center;
	padding: 1em;
	border-top-right-radius: 8px;
	border-top-left-radius: 8px;
	border-bottom-right-radius: 8px;
	border-bottom-left-radius: 8px;
}

.resume small,
.resume p,
.resume h3,
.resume h4 {
	color: #fff;
}

.resume-body {
	padding: 1em;
}

label {
	display: inline-block;
	max-width: 100%;
	margin-bottom: 5px;
	font-weight: bold;
}

input[type="search"] {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

input[type="radio"],
input[type="checkbox"] {
	margin: 4px 0 0;
	margin-top: 1px \9;
	line-height: normal;
}

input[type="file"] {
	display: block;
}

input[type="range"] {
	display: block;
	width: 100%;
}

select[multiple],
select[size] {
	height: auto;
}

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
	outline: thin dotted;
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}

output {
	display: block;
	padding-top: 7px;
	font-size: 14px;
	line-height: 1.42857;
	color: #555;
}

.form-control {
	display: block;
	width: 100%;
	height: 34px;
	padding: 6px 12px;
	font-size: 14px;
	line-height: 1.42857;
	color: #555;
	background-color: #fff;
	background-image: none;
	border: 1px solid #ccc;
	border-radius: 4px;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	-webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	-o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.form-control:focus {
	border-color: #66afe9;
	outline: 0;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.form-control::-moz-placeholder {
	color: #999;
	opacity: 1;
}

.form-control:-ms-input-placeholder {
	color: #999;
}

.form-control::-webkit-input-placeholder {
	color: #999;
}

.form-control::-ms-expand {
	border: 0;
	background-color: transparent;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
	background-color: #eee;
	opacity: 1;
}

.form-control[disabled],
fieldset[disabled] .form-control {
	cursor: not-allowed;
}

textarea.form-control {
	height: auto;
}

input[type="search"] {
	-webkit-appearance: none;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
	input[type="date"].form-control,
	input[type="time"].form-control,
	input[type="datetime-local"].form-control,
	input[type="month"].form-control {
		line-height: 34px;
	}
	input[type="date"].input-sm,
	.input-group-sm input[type="date"],
	input[type="time"].input-sm,
	.input-group-sm input[type="time"],
	input[type="datetime-local"].input-sm,
	.input-group-sm input[type="datetime-local"],
	input[type="month"].input-sm,
	.input-group-sm input[type="month"] {
		line-height: 30px;
	}
	input[type="date"].input-lg,
	.input-group-lg input[type="date"],
	input[type="time"].input-lg,
	.input-group-lg input[type="time"],
	input[type="datetime-local"].input-lg,
	.input-group-lg input[type="datetime-local"],
	input[type="month"].input-lg,
	.input-group-lg input[type="month"] {
		line-height: 46px;
	}
}

.form-group {
  margin-bottom: 15px;
  &.error {
    & label {
      color: #dc3545!important;
    }
    & input {
      border: solid 1px #dc3545;
    }
  }
}

.radio,
.checkbox {
	position: relative;
	display: block;
	margin-top: 10px;
	margin-bottom: 10px;
}

.radio label,
.checkbox label {
	min-height: 20px;
	padding-left: 20px;
	margin-bottom: 0;
	font-weight: normal;
	cursor: pointer;
}

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
	position: absolute;
	margin-left: -20px;
	margin-top: 4px;
}

.radio+.radio,
.checkbox+.checkbox {
	margin-top: -5px;
}

.radio-inline,
.checkbox-inline {
	position: relative;
	display: inline-block;
	padding-left: 20px;
	margin-bottom: 0;
	vertical-align: middle;
	font-weight: normal;
	cursor: pointer;
}

.radio-inline+.radio-inline,
.checkbox-inline+.checkbox-inline {
	margin-top: 0;
	margin-left: 10px;
}

input[type="radio"][disabled],
input[type="radio"].disabled,
fieldset[disabled] input[type="radio"],
input[type="checkbox"][disabled],
input[type="checkbox"].disabled,
fieldset[disabled] input[type="checkbox"] {
	cursor: not-allowed;
}

.radio-inline.disabled,
fieldset[disabled] .radio-inline,
.checkbox-inline.disabled,
fieldset[disabled] .checkbox-inline {
	cursor: not-allowed;
}

.radio.disabled label,
fieldset[disabled] .radio label,
.checkbox.disabled label,
fieldset[disabled] .checkbox label {
	cursor: not-allowed;
}

.form-control-static {
	padding-top: 7px;
	padding-bottom: 7px;
	margin-bottom: 0;
	min-height: 34px;
}

.form-control-static.input-lg,
.form-control-static.input-sm {
	padding-left: 0;
	padding-right: 0;
}

.input-sm {
	height: 30px;
	padding: 5px 10px;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 3px;
}

select.input-sm {
	height: 30px;
	line-height: 30px;
}

textarea.input-sm,
select[multiple].input-sm {
	height: auto;
}

.form-group-sm .form-control {
	height: 30px;
	padding: 5px 10px;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 3px;
}

.form-group-sm select.form-control {
	height: 30px;
	line-height: 30px;
}

.form-group-sm textarea.form-control,
.form-group-sm select[multiple].form-control {
	height: auto;
}

.form-group-sm .form-control-static {
	height: 30px;
	min-height: 32px;
	padding: 6px 10px;
	font-size: 12px;
	line-height: 1.5;
}

.input-lg {
	height: 46px;
	padding: 10px 16px;
	font-size: 18px;
	line-height: 1.33333;
	border-radius: 6px;
}

select.input-lg {
	height: 46px;
	line-height: 46px;
}

textarea.input-lg,
select[multiple].input-lg {
	height: auto;
}

.form-group-lg .form-control {
	height: 46px;
	padding: 10px 16px;
	font-size: 18px;
	line-height: 1.33333;
	border-radius: 6px;
}

.form-group-lg select.form-control {
	height: 46px;
	line-height: 46px;
}

.form-group-lg textarea.form-control,
.form-group-lg select[multiple].form-control {
	height: auto;
}

.form-group-lg .form-control-static {
	height: 46px;
	min-height: 38px;
	padding: 11px 16px;
	font-size: 18px;
	line-height: 1.33333;
}

.has-feedback {
	position: relative;
}

.has-feedback .form-control {
	padding-right: 42.5px;
}

.form-control {
	height: 50px;
  box-shadow: none;
}

.form-control:focus {
	box-shadow: none;
	border-color: #472d8c;
}

@-webkit-keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.fade {
	-webkit-animation: fadeIn .5s ease-in 1 forwards;
  animation: fadeIn .5s ease-in 1 forwards;
  opacity: 0;
}

.block {
  display: block !important;
}

.inline {
  display: inline !important;
}

.inline-block {
  display: inline-block;
}

.img-success {
	width: 50%;
	margin: 0 auto
}
