.faq-question {
  h3 {
    margin: 0;
    cursor: pointer;
    color: #472d8c;
  }

  .card-header-icon {
    float: right;
  }

  .card-content {
    overflow: hidden;
    max-height: 0;
    /* add browser prefixes */
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s  ease-out;
    -o-transition: all 0.3s ease-out;

    &.isOpen {
      max-height: 600px;
      transition: all 0.3s ease-in;
      -webkit-transition: all 0.3s ease-in;
      -moz-transition: all 0.3s ease-in;
      -ms-transition: all 0.3s ease-in;
      -o-transition: all 0.3s ease-in;
    }
  }
}
