.resume-info {
  .resume {
    color: #fff;
    background: #472d8c;
    text-align: center;
    padding: 1em;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;

    small,
    p,
    h3,
    h4 {
      color: #fff;
    }

    .btn-theme {
      width: 100%;
      display: block;
      margin: 0.25em 0;
    }

    .enfatize-text {
      color: #fddd00 !important;
    }
  }

  .resume--comments,
  .resume--aside {
    padding: 0;
  }

  .resume-body-title {
    margin: 0 0 1em 0;
    font-weight: bold;
    text-align: left;
  }

  .resume--price-final {
    font-size: 30px;
    font-weight: bold;
    margin: 0;
  }

  .resume-list-item {
    text-align: left;
    margin-bottom: 1em;
  }

  .resume-list-item p {
    margin: 0;
    font-weight: bold;
  }

  .resume-total {
    margin-top: 1em;
    padding-top: 1em;
    border-top: 1px solid #3d2779;
  }

  .resume--price-final-note {
    font-size: 16px;
  }
}