
.sell-user-info {
  .card--form .card-body .card-body-column {
    width: 100%;
  }

  .btn--save {
    &:disabled {
      cursor: not-allowed;
      opacity: 0.7;
    }
  }

  @media (max-width: 992px) {
    .aside-main {
      .aside,
      .main {
        width: 100%
      }
    }
  }

  @media (min-width: 768px) {
    .card--form .card-body .card-body-column {
      width: 50%;
    }
  }
}